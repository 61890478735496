<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>クーポン名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength='50'
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="hasShopRole">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <SearchSelect
                                ref="searchSelect"
                                :options="shopList"
                                :multiple="false"
                                @change-selection="setSelectedShopId"
                                :closeOnSelect="true"
                                :searchKeyMinLength="searchKeyLength"
                                :searchAction="searchAction"
                                :resetOptions="resetOptions"
                                :params="params"
                                :searchField="searchField"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>発行元</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="issuer"
                                  v-model="searchForm.issuer"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in issuerTypes"
                                    :key="item.value"
                                    :value="item.value"
                                  >{{ item.label }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>利用日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>当月利用件数</template>
                  <template v-slot:num>{{ couponUseTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox :dataTable="dataTable" :labels="labels"> </TableWithCheckbox>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :modulePath="modulePath"
                  :searchConditions="searchConditions"
                  :subdomain="subdomain"
                  :listLength="couponUseCount"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!couponUseList.length || isDisable" class="btn btn-black" :handle-submit="hanldeClickDownload" button-text="CSVダウンロード" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload
      v-if="downloadCSVModal"
      @cancelDownloadCsv="cancelDownloadCsv"
      @acceptDownloadCsv="acceptDownloadCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
//mixins
import nav from '@/mixins/nav/coupon-event';
import flatpickr from '@/mixins/plugin/flatpickr';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import { formatDateAndTime } from '@/helpers/formatData';
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
import { CouponConstants } from '@/constants/coupon';
import { addThreeDotEndLine, breakLineWithComma, endline } from '@/helpers/formatData';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';

export default {
  name: 'EventCouponUse',
  data: function() {
    return {
      pageName: 'クーポン',
      modulePath: 'coupon/getEventUseCoupons',
      searchConditions: {},
      storeModule: 'coupon',
      searchFields: ['name', 'issuer', 'shopId', 'startDate', 'endDate'],
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      params: {
        status: StatusConstants.shop.approved.value,
        withTrashed: 1,
      },
      searchField: 'name',
      issuerTypes: Object.values(CouponConstants.issuerType),
      storedField: 'idRequestExportEventCoupon',
    };
  },
  mixins: [nav, flatpickr, search, download],
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    SearchSelect,
    PaginationLog,
    ModalConfirmDownload,
    TableWithCheckbox,
  },
  computed: {
    ...mapGetters({
      couponUseList: 'coupon/couponUseList',
      couponUseCount: 'coupon/couponUseCount',
      shopList: 'shop/shopList',
      couponUseTotal: 'coupon/couponUseTotal',
      hasShopRole: 'auth/hasShopRole',
      hasCustomersRole: 'auth/hasCustomersRole',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    dataTable() {
      return (
        this.couponUseList &&
        this.couponUseList.map((item) => {
          return {
            customerName: !item.customer?.nameSei ? '退会ユーザー' : endline(item.customer?.nameSei + ' ' + item.customer?.nameMei, 21),
            userId: item.customer?.cpmUserId ? breakLineWithComma((item.customer?.cpmUserId + ',') + item.customer?.number) : item.customer?.number,
            createDate: formatDateAndTime(item.createDate),
            name: addThreeDotEndLine(item.name, 15),
            shopName: addThreeDotEndLine(item.shop?.name, 15),
            issuer: item?.issuer
          };
        })
      );
    },
    labels() {
      return [
        ...this.hasCustomersRole ? [{ key: 'customerName', name: 'お名前' }] : [],
        { key: 'userId', name: 'user ID', tdClass: 'pre-line' },
        { key: 'createDate', name: '利用日' },
        { key: 'name', name: 'クーポン名', tdClass: 'dataTable-break w-145' },
        ...this.hasShopRole ?  [{ key: 'shopName', name: '利用店舗', tdClass: 'dataTable-break w-145' }] : [],
        { key: 'issuer', name: '発行元' },
      ];
    }
  },
  methods: {
    setSelectedShopId(shop) {
      this.searchForm.shopId = shop?.id;
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch('coupon/getEventCouponCSV', {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
  },
  async created() {
    const loading = this.$loading.show();
    await this.$store.dispatch('coupon/getEventCouponUseTotal', { subdomain: this.subdomain })
    this.$loading.clear(loading);
  }
};
</script>
